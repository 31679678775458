import { useQuery } from 'react-query';
import { type UseQueryOptions } from 'react-query/types/react/types';
import { type MyScheduleResponse } from '../dtos/MyScheduleDto';
import { fetcher, logger } from '../utils/Fetcher';
import { API_URLS } from '../utils/urls';

const useMySchedule = <T = MyScheduleResponse,>(props?: {
  options?: Omit<UseQueryOptions<T>, 'queryKey' | 'queryFn'>;
  year?: number;
}) => {
  return useQuery(
    'MySchedule',
    async () => {
      try {
        const response = await fetcher.get<T>(API_URLS.MY_SCHEDULE(props?.year));

        return response;
      } catch (error) {
        logger.error(error);
        throw error;
      }
    },
    props?.options
  );
};

export default useMySchedule;

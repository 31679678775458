import { css } from '@emotion/react';
import { Box, Text, Title } from '@inflearn/ds-react';

type EmptyStateProps = {
  title: string;
  description: string;
  buttonNode?: React.ReactNode;
};

const EmptyState = ({ title, description, buttonNode }: EmptyStateProps) => {
  return (
    <Box component="section" css={styleEmptyStateSection}>
      <Box css={styleEmptyStateWrapper}>
        <Title order={2} size={20} color="gray.9" weight={500}>
          {title}
        </Title>
        <Text size={16} color="gray.5" mt={4}>
          {description}
        </Text>
        {buttonNode !== undefined && <Box mt={16}>{buttonNode}</Box>}
      </Box>
    </Box>
  );
};

const styleEmptyStateSection = css({
  display: 'flex',
  flexFlow: 'column nowrap',
  padding: '16px 0 80px',
  width: '100%',
  maxWidth: '1200px'
});

const styleEmptyStateWrapper = css({
  display: 'flex',
  flexFlow: 'column nowrap',
  alignItems: 'center',
  justifyContent: 'center',
  height: '424px'
});

export default EmptyState;

import { useMutation } from 'react-query';
import { fetcher, logger } from '../utils/Fetcher';
import { API_URLS } from '../utils/urls';

const useDeleteMySchedule = (year?: number) => {
  return useMutation('delete-my-schedule', async (id: number) => {
    try {
      const response = await fetcher.delete<{ ok: boolean }>(
        API_URLS.DELETE_SCHEDULE(id, year ?? 2023),
        {
          id
        }
      );

      if (!response.ok) {
        throw new Error();
      }

      return response;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  });
};

export default useDeleteMySchedule;

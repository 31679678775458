import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { filter, forEach, includes, isEmpty, map, some, sortBy, split, trim } from 'lodash-es';
import { PROGRAM_TYPE, type SessionSubType } from '../components/infcon-2023/commons/constants';
import { type SpeakerFilterType } from '../components/infcon-2023/session/SpeakerSessionFilterModal';
import {
  type IntermissionTimeRowType,
  type ProgramRowProps,
  type SessionTableRowType,
  type SessionTableTimeRowType,
  type SpeakerRowType
} from '../components/infcon-2023/session/Table';
import { type TableRowType } from '../components/infcon-2024/session/TableRow';
import { unescape } from '../utils/escape';
import type MyScheduleDto from './MyScheduleDto';
import { type PageData, type PageResponse, type SessionPageData } from './PageDto';

dayjs.extend(customParseFormat);

export type SessionPagesResponse = {
  ok: boolean;
  data: PageResponse[];
};

type SessionData = PageData & Required<SessionPageData> & { title: string; profileUrl: string };

class SessionPagesDto {
  readonly sessions: SessionData[];

  constructor(response: SessionPagesResponse) {
    this.sessions = response.data.map(
      ({ id, url, data: session, title, subType, type }): SessionData => ({
        width: session.width,
        tag_id: session.tag_id,
        has_post: session.has_post,
        backgroundColor: session.backgroundColor,
        trackName: session.trackName ?? '',
        affiliation: session.affiliation ?? '',
        speakerName: session.speakerName ?? '',
        sessionLocation: session.sessionLocation ?? '',
        sessionSchedule: session.sessionSchedule ?? '',
        profileUrl: url,
        title: unescape(title),
        id,
        type,
        subType: subType.toLowerCase() as SessionSubType
      })
    );
  }

  getSpeakerSchedules(myScheduleDto?: MyScheduleDto): SessionTableRowType[] {
    const schedules: SessionTableRowType[] = [];

    forEach(SpeakerTableTimes, (session: TableTime) => {
      if (session.type === 'intermission') {
        const intermissionText = getIntermissionText(session.startedAt);
        schedules.push({
          type: session.type,
          time: `${session.startedAt} - ${session.endedAt}`,
          place: session.startedAt === '17:40' ? '202호' : '전체',
          content: intermissionText.content
        });
        return;
      }

      if (session.type === 'speaker') {
        const sessionsByStartTime = sortBy(
          this.sessions.filter((sessionData: SessionData) =>
            sessionData.sessionSchedule.includes(session.startedAt)
          ),
          'sessionLocation'
        );
        if (!isEmpty(sessionsByStartTime)) {
          const sessionTableTimeRow: SessionTableTimeRowType = {
            type: session.type,
            startedAt: session.startedAt,
            endedAt: session.endedAt,
            offsetTop: {
              default: '0',
              mobile: '0'
            },
            speakerRows: map(sessionsByStartTime, (row: SessionData) => {
              return {
                place: row.sessionLocation,
                speaker: {
                  name: row.speakerName,
                  company: row.affiliation,
                  profileUrl: row.profileUrl
                },
                sessionName: unescape(row.title),
                trackName: row.trackName,
                key: row.speakerName,
                sessionId: row.id,
                includedMySchedule: myScheduleDto?.hasSessionId(row.id) ?? false
              };
            })
          };

          schedules.push(sessionTableTimeRow);
        }
      }
    });

    return schedules;
  }

  getFilteredSpeakerSchedules(
    filter: SpeakerFilterType,
    myScheduleDto?: MyScheduleDto
  ): SessionTableRowType[] {
    const originalSessions = this.getSpeakerSchedules(myScheduleDto);
    if (originalSessions == null) {
      return [];
    }

    /* 필터가 없을 경우 원래의 값 노출 */
    if (filter.places.length === 0 && filter.categories.length === 0) {
      return originalSessions;
    }

    const filteredSessions: SessionTableRowType[] = [];
    forEach(originalSessions, (session: SessionTableRowType) => {
      /* intermission 삭제 */
      if ('type' in session && session.type === 'intermission') {
        return;
      }

      /* 장소, 카테고리 교집합으로 필터 포함 */
      const hasPlace = (speaker: SpeakerRowType) => {
        const placeNumberString = parseInt(speaker.place).toString();
        return (
          filter.places.length === 0 ||
          some(filter.places, (place) => place.includes(placeNumberString))
        );
      };

      const hasCategory = (speaker: SpeakerRowType) => {
        const trackNames = speaker.trackName
          ? map(split(speaker.trackName.replaceAll('\b', ''), ','), trim)
          : [];

        return (
          filter.categories.length === 0 ||
          some(filter.categories, (category) => includes(trackNames, category))
        );
      };

      const filteredSpeakerRows =
        'speakerRows' in session
          ? session.speakerRows.filter((speaker) => hasPlace(speaker) && hasCategory(speaker))
          : [];

      if (filteredSpeakerRows.length === 0) {
        return;
      }

      filteredSessions.push({
        ...session,
        speakerRows: filteredSpeakerRows
      });
    });

    return filteredSessions;
  }

  getProgramSchedules(): SessionTableRowType[] {
    const schedules: SessionTableRowType[] = [];
    forEach(ProgramTrackNames, (trackName) => {
      const sessionsByTrackName = sortBy(
        this.sessions.filter((sessionData: SessionData) =>
          sessionData.trackName.includes(trackName)
        ),
        'sessionSchedule'
      );

      if (!isEmpty(sessionsByTrackName)) {
        const programRow: ProgramRowProps = {
          programType: trackName, // 핸즈온, 네트워킹, 기업 구분
          rows: map(sessionsByTrackName, (row: SessionData) => {
            return {
              time: row.sessionSchedule,
              place: row.sessionLocation,
              speaker: {
                name: row.speakerName,
                company: row.affiliation,
                profileUrl: row.profileUrl
              },
              sessionName: unescape(row.title),
              key: row.speakerName,
              sessionId: row.id
            };
          })
        };
        schedules.push(programRow);
      }
    });

    return schedules;
  }
}

const TRACK_LOOKUP: Record<string, string> = {
  '101호': 'Track 1',
  '102호': 'Track 2',
  '103호': 'Track 3',
  '104호': 'Track 4',
  '105호': 'Track 5',
  '209호': 'Track 6',
  '205A호': '딥다이브',
  '205B호': '딥다이브'
};

const SUB_TYPE_LOOKUP = {
  SESSION: 'speaker',
  PARTICIPATION: 'participation'
} as const;

export const Dto = (response: SessionPagesResponse, tableTimes: TableTime[]) => {
  const flatten = response.data.map((page) => {
    const [startedAt, endedAt] = page.data.sessionSchedule
      ? page.data.sessionSchedule.split(' - ')
      : ['', ''];
    const location = page.data.sessionLocation
      ? page.data.sessionLocation?.replaceAll(' ', '')
      : '';

    const startTime = dayjs(startedAt, 'HH:mm');
    const endTime = dayjs(endedAt, 'HH:mm');
    const duration = endTime.diff(startTime, 'minutes');

    return {
      type: SUB_TYPE_LOOKUP[page.subType],
      id: page.id,
      slug: page.slug,
      title: page.title,
      speakerName: page.data.speakerName ?? '-',
      profileUrl: page.url,
      companyName: page.data.affiliation ?? '-',
      location,
      track: TRACK_LOOKUP[location],
      categories: page.data.trackName ? page.data.trackName.split(/\s*,\s*/) : [],
      startedAt,
      endedAt,
      duration,
      hasSchedule: page.hasSchedule
    };
  });

  const tableData: TableRowType[] = [];

  tableTimes.forEach((sessionTime) => {
    if (sessionTime.type === 'intermission') {
      tableData.push({
        type: 'intermission',
        content: sessionTime.startedAt === '13:00' ? '점심시간' : ''
      });
      return;
    }

    const sortByTrack = sortBy(
      filter(flatten, { startedAt: sessionTime.startedAt, endedAt: sessionTime.endedAt }),
      'track'
    );
    tableData.push(...sortByTrack);
  });

  return tableData;
};

export const filterSpeakerSessions = (
  rows: TableRowType[],
  filter: SpeakerFilterType
): TableRowType[] => {
  let lastWasSpeaker = false;
  return rows.filter((row, index) => {
    if (row.type === 'speaker') {
      const matchesPlace = filter.places.length === 0 || filter.places.includes(row.location);
      const matchesCategory =
        filter.categories.length === 0 ||
        filter.categories.some((category) => row.categories.includes(category));
      lastWasSpeaker = matchesPlace && matchesCategory;
      return lastWasSpeaker;
    }

    if (row.type === 'intermission') {
      const prevRow = rows[index - 1];
      const nextRow = rows[index + 1];
      const isBetweenSpeakers = prevRow?.type === 'speaker' && nextRow?.type === 'speaker';
      const includeIntermission = isBetweenSpeakers && lastWasSpeaker;
      lastWasSpeaker = false; // Reset after an intermission
      return includeIntermission;
    }

    return false; // Non-matching row
  });
};

export type TableTime = {
  startedAt: string;
  endedAt: string;
  type: 'speaker' | 'program' | 'intermission';
};

export const SpeakerTableTimes: TableTime[] = [
  {
    startedAt: '09:50',
    endedAt: '10:20',
    type: 'speaker'
  },
  {
    startedAt: '10:20',
    endedAt: '10:30',
    type: 'intermission'
  },
  {
    startedAt: '10:30',
    endedAt: '11:10',
    type: 'speaker'
  },
  {
    startedAt: '10:30',
    endedAt: '10:50',
    type: 'speaker'
  },
  {
    startedAt: '11:05',
    endedAt: '11:25',
    type: 'speaker'
  },
  {
    startedAt: '11:10',
    endedAt: '11:25',
    type: 'intermission'
  },
  {
    startedAt: '11:25',
    endedAt: '12:05',
    type: 'speaker'
  },
  {
    startedAt: '11:40',
    endedAt: '12:00',
    type: 'speaker'
  },
  {
    startedAt: '12:15',
    endedAt: '12:35',
    type: 'speaker'
  },
  {
    startedAt: '12:05',
    endedAt: '12:20',
    type: 'intermission'
  },
  // 이하 모든 세션의 시간 40분으로 동일
  {
    startedAt: '12:20',
    endedAt: '13:00',
    type: 'speaker'
  },
  {
    startedAt: '13:00',
    endedAt: '14:00',
    type: 'intermission'
  },
  {
    startedAt: '14:00',
    endedAt: '14:40',
    type: 'speaker'
  },
  {
    startedAt: '14:40',
    endedAt: '15:00',
    type: 'intermission'
  },
  {
    startedAt: '15:00',
    endedAt: '15:40',
    type: 'speaker'
  },
  {
    startedAt: '15:40',
    endedAt: '16:00',
    type: 'intermission'
  },
  {
    startedAt: '16:00',
    endedAt: '16:40',
    type: 'speaker'
  },
  {
    startedAt: '16:40',
    endedAt: '17:00',
    type: 'intermission'
  },
  {
    startedAt: '17:00',
    endedAt: '17:40',
    type: 'speaker'
  },
  {
    startedAt: '17:40',
    endedAt: '18:00',
    type: 'intermission'
  },
  {
    startedAt: '17:40',
    endedAt: '18:00',
    type: 'speaker'
  }
];

export const ProgramTableTimes: TableTime[] = [
  {
    startedAt: '10:30',
    endedAt: '11:50',
    type: 'program'
  },
  {
    startedAt: '11:50',
    endedAt: '12:20',
    type: 'intermission'
  },
  {
    startedAt: '12:20',
    endedAt: '13:40',
    type: 'program'
  },
  {
    startedAt: '14:20',
    endedAt: '14:40',
    type: 'intermission'
  },
  {
    startedAt: '14:40',
    endedAt: '16:00',
    type: 'program'
  },
  {
    startedAt: '16:00',
    endedAt: '16:20',
    type: 'intermission'
  },
  {
    startedAt: '15:30',
    endedAt: '18:00',
    type: 'program'
  },
  {
    startedAt: '18:00',
    endedAt: '18:20',
    type: 'intermission'
  }
];

const ProgramTrackNames = [
  PROGRAM_TYPE.handsOn,
  PROGRAM_TYPE.company,
  PROGRAM_TYPE.networking
] as const;

const getIntermissionText = (startedAt: string): Pick<IntermissionTimeRowType, 'content'> => {
  if (startedAt === '13:00') {
    return {
      content: '점심시간'
    };
  }

  return {
    content: ''
  };
};

export default SessionPagesDto;

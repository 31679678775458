import { useQuery } from 'react-query';
import { logger, fetcher } from '../utils/Fetcher';
import { API_URLS } from '../utils/urls';

export type SessionResponse = {
  ok: boolean;
  data: Session;
};

export type Session = {
  id: number;
  name: string;
  thumbnailUrl: string;
  role: SessionRoleDto[];
  isMobileVerified: boolean;
  realname: string;
};

export type SessionRoleDto = {
  code: string;
  name: string;
};

export type SessionUnAuthorizationResponse = {
  ok: boolean;
  data: '';
  statusCode: 'UNAUTHORIZED';
  message: '로그인이 필요합니다';
};

const useSession = () => {
  return useQuery('session', async () => {
    try {
      const response = await fetcher.get<SessionResponse | SessionUnAuthorizationResponse>(
        API_URLS.USER_SESSION
      );

      if (response.data === '' && response.statusCode === 'UNAUTHORIZED') {
        return;
      }

      if (response.data === '') {
        throw new Error();
      }

      return response;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  });
};

export default useSession;

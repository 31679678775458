import { Box, Title } from '@inflearn/ds-react';
import { styleSessionTableSection } from '../../infcon-2023/session/Table/styles';
import TableRow, { type TableRowType } from './TableRow';

type SessionSpeakersProps = {
  sessions: TableRowType[];
};

const ProgramTable = ({ sessions }: SessionSpeakersProps) => {
  return (
    <Box component="section" css={styleSessionTableSection}>
      <Title order={2} className="visually-hidden">
        프로그램 세션 시간표
      </Title>
      <Box component="ul" css={{ width: '100%', li: { width: '100%' } }}>
        {sessions.map((session, index) => (
          <TableRow key={index} session={session} />
        ))}
      </Box>
    </Box>
  );
};

export default ProgramTable;

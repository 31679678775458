import { useMutation } from 'react-query';
import { type AntmanErrorType } from '../@types/schemas';
import { fetcher, logger } from '../utils/Fetcher';
import { API_URLS } from '../utils/urls';

const useAddMySchedule = (year?: number) => {
  return useMutation('add-my-schedule', async (id: number) => {
    try {
      const response = await fetcher.post<{ ok: true } | AntmanErrorType>(
        API_URLS.ADD_SCHEDULE(id, year ?? 2023),
        {
          id
        }
      );

      if (!response.ok) {
        throw new Error(response.err.msg);
      }

      return response;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  });
};

export default useAddMySchedule;
